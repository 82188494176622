               
               
<template>
  <WeContainer card="" v-if="ready">
    <WeCard class="mb-4">
      <div class="row align-items-center">
        <div class="col">
          <h5 class="mb-0">{{ getTitle }}</h5>
        </div>
        <div class="col-auto">
          <div class="row align-items-center">
            <div class="col-auto p-0">
              <WeLanguageSwitcher v-model="emailTemplate.lang" />
            </div>
            <div class="col pl-0">
              <!-- Submit -->
              <WeSubmitButton
                parent-class="text-center"
                v-bind:submit-status="submit"
                v-bind:update="$route.params.id"
                v-on:submit="submitForm"
              />
              <!-- ./Submit -->
            </div>
          </div>
        </div>
      </div>
    </WeCard>
    <!-- Title -->
    <WeCard class="mb-4">
      <WeInput
        class="mb-0"
        name="title"
        v-model="form.title"
        label="Başlık"
        v-bind:error="$v.form.title.$error"
      />
    </WeCard>
    <!-- ./Title -->
    <div class="row">
      <div class="col-12 col-lg-8 col-xl-9">
        <div class="border rounded">
          <!-- Mail Header -->
          <div class="d-block email-type-header">
            <img v-bind:src="siteLogo" width="150px" />
          </div>
          <!-- ./Mail Header -->

          <!-- Mail Content -->
          <div class="d-block email-type-content bg-white">
            <div>
              <div
                @click="selectedDiv = 1"
                class="border border-dark"
                v-bind:style="`max-width: 100%; height: auto; margin-top: 10px;`"
              >
                <div @click="topText = null" style="float: right; padding: 5px">
                  <i class="fas fa-trash-alt" style="font-size: 25px"></i>
                </div>
                <div
                  v-if="topText == null"
                  style="
                    display: flex;
                    justify-content: center;
                    margin: auto;
                    align-items: center;
                    padding-top: 3%;
                  "
                  @click="selectedDiv = 1"
                >
                  <button type="button" class="btn btn-info align-self-center">
                    Metin Ekle
                  </button>
                </div>

                <p
                  :style="`color: ${styleObject['color']}; font-size : ${styleObject['fontSize']}; font-weight : ${styleObject['fontWeight']}; text-align : ${styleObject['textAlign']};white-space: pre-line;`"
                >
                  {{ topText }}
                </p>
              </div>
              <div
                @click="selectedDiv = 2"
                class="border border-dark"
                style="max-width: 100%; height: auto; margin-top: 10px"
              >
                <div @click="image = null" style="float: right; padding: 5px">
                  <i class="fas fa-trash-alt" style="font-size: 25px"></i>
                </div>
                <div
                  v-if="image == null"
                  style="
                    display: flex;
                    justify-content: center;
                    margin: auto;
                    align-items: center;
                    padding-top: 3%;
                  "
                >
                  <button
                    type="button"
                    class="btn btn-info align-self-center"
                    @click="selectedDiv = 2"
                  >
                    Resim Ekle
                  </button>
                </div>
                <a v-bind:href="`${styleObject['imgUrl']}`">
                  <img
                    v-bind:src="`${image}`"
                    alt=""
                    :style="`width: 100%;${image} ? display : none : '';`"
                  />
                </a>
                
              </div>

              <!-- ./Editor -->
            </div>
          </div>
          <!-- ./Mail Content -->

          <!-- Mail Footer -->
          <div class="d-block email-type-footer">
            <!-- Title -->
            <div class="d-block text-center mb-3">
              <span class="font-weight-bold">Takip Edin</span>
            </div>
            <!-- ./Title -->

            <!-- Social Icons -->
            <div class="row align-items-center justify-content-center mb-3">
              <i
                v-for="(icon, index) in socialIcons"
                v-bind:key="`icon-${index}`"
                v-bind:class="['mr-4', `${icon} fa-lg`]"
              ></i>
            </div>
            <!-- ./Social Icons -->

            <!-- Footer Info -->
            <div class="block" v-for="info in siteInfo" v-bind:key="info.name">
              <template v-if="config[`site.${info.name}`]">
                <b>{{ info.label }}:</b> {{ config[`site.${info.name}`] }}
              </template>
            </div>
            <!-- ./Footer Info -->
          </div>
          <!-- ./Mail Footer -->
        </div>
      </div>
      <EditorComponent
        :selectedTemplateIndex="selectedDiv"
        :textId="textId"
        :imageId="imageId"
        v-on:imageUploaded="updateImage($event)"
        v-on:textTop="updateText($event)"
      />
    </div>
  </WeContainer>
  <WeLoading v-else />
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import Verte from "verte";
import "verte/dist/verte.css";
import { json } from "body-parser";
import { replace } from "lodash-es";
const ContentType = () => import("./components/ContentTypes/Index.vue");
import EditorComponent from "./components/Templates/SideEditorComponent.vue";

export default {
  name: "Detail",
  components: {
    EditorComponent,
    ContentType,
    Verte,
  },
  data() {
    return {
      cdn_domains: null,
      media: null,
      textId: Math.ceil(Math.random() * 1000000),
      imageId: Math.ceil(Math.random() * 1000000),
      selectedDiv: 1,
      image: null,
      styleObject: {},
      topText: null,
      ready: false,
      submit: false,
      inputValue: null,
      form: {
        title: null,
        contents: [],
      },

      socialIcons: [
        "fab fa-facebook",
        "fab fa-twitter",
        "fab fa-instagram",
        "fab fa-youtube",
        "fab fa-linkedin",
      ],
      siteInfo: [
        {
          name: "address",
          label: "Adres",
        },
        {
          name: "email",
          label: "E-Posta",
        },
        {
          name: "phone",
          label: "Telefon",
        },
        {
          name: "mersis_no",
          label: "Mersis",
        },
      ],
    };
  },

  validations: {
    form: {
      title: {
        required,
      },
    },
  },
  methods: {
    ...mapActions("emailTemplate", ["create", "update", "getById"]),
    ...mapMutations("shared", ["setMessage", "clearMessage"]),

    getEmailTemplateById() {
      if (this.$route.params.id) {
        this.getById({
          id: this.$route.params.id,
          onSuccess: (result) => {
            console.log(result);
            this.form = result;
            var res = this.form.contents[0].value;
            this.topText = res.Text.text;
            this.styleObject = res.Text.style;
            var mediaData = this.form.contents[0].media;
            this.image = `https://${this.cdn_domains}/${mediaData.path.original}`;
          },
          onFinish: () => {
            this.ready = true;
          },
        });
      } else {
        this.ready = true;
      }
    },
    updateText(param) {
      this.topText = param[0];
      this.styleObject = param[1];
    },
    updateImage(param) {
      this.media = param.files;
      this.image = param.files[0].fileData;

      // this.form.contents.push({'image' : this.image});
    },
    addEmailTemplate() {
      this.create({
        form: this.form,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.showConfirmation("Detaya Git", result.data.id);
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    updateEmailTemplate() {
      this.update({
        id: this.$route.params.id,
        form: this.form,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.showConfirmation("Sayfada Kal");
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    validateForm() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.$swal({
          title: "Hata",
          text: "Lütfen zorunlu alanları doldurun",
          icon: "error",
        });

        return false;
      } else {
        return true;
      }
    },
    submitForm() {
      if (!this.validateForm()) {
        return;
      }
      this.submit = true;
      this.form.contents = [];
      var data = {
        Text: {
          text: this.topText,
          style: this.styleObject,
        },
        Image: {
          image: "",
        },
      };

      this.form.contents.push({
        type: "editor",
        value: data,
        Media: this.media,
      });
      if (this.$route.params.id) {
        this.updateEmailTemplate();
      } else {
        this.addEmailTemplate();
      }
    },
    showAlert(result, callback) {
      this.setMessage(result);
      this.$swal(this.shared.message).then((confirm) => {
        if (callback && confirm.isConfirmed) callback();
      });
      this.clearMessage();
    },
    showConfirmation(cancelButtonText, id) {
      this.$swal({
        title: "İşlem Başarılı",
        text: "E-Posta Şablon listesine dönebilir veya bu sayfada kalabilirsiniz.",
        icon: "success",
        showCancelButton: true,
        cancelButtonText: cancelButtonText,
        confirmButtonText: "Liste Sayfasına Dön",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.redirectToList();
        } else {
          if (id) {
            this.$router.push(`detail/${id}`);
          } else {
            window.location.reload();
          }
        }
      });
    },
    redirectToList() {
      this.$router.push("/email-templates");
    },
  },
  computed: {
    ...mapState({
      shared: (state) => state.shared,
      emailTemplate: (state) => state.emailTemplate,
      config: (state) => state?.session?.config,
      session: (state) => state?.session,
    }),
    siteLogo() {
      return this.config["site.logo"];
    },
    getTitle() {
      return this.$route.params.id
        ? "E-POSTA ŞABLONU DÜZENLE"
        : "E-POSTA ŞABLONU EKLE";
    },
    submitButtonText() {
      return this.$route.params.id ? "Güncelle" : "Kaydet";
    },
    selectedTypes() {
      return helper.arrayPluck(this.form.contents, "type");
    },
  },
  mounted() {
    this.cdn_domains = this.config["app.cdn_domain"];
    this.getEmailTemplateById();
  },
};
</script>
<style lang="scss" scoped>
.email-type {
  &-footer,
  &-header {
    padding: 20px;
    text-align: center;
    background-color: rgb(229, 229, 229);
  }
  &-content {
    padding: 20px;
  }
}
</style>