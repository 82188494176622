<template>
  <div class="border templateEditor">
    <div v-if="selectedTemplateIndex == 1">
      <div style="padding: 10px">
        <label for="gan-id" class="custom-label">Metin Giriniz</label>
        <textarea
          id="gan-id"
          class="form-control"
          v-bind:style="styleObject"
          style="min-height: 150px"
          v-model="textData"
        ></textarea>
        <div class="row" style="margin-top: 10px">
          <Button
            type="button"
            class="btn btn-secondary btn-sm"
            style="
              border-radius: 5px;
              margin: auto 0px auto 10px;
              margin-left: 16px;
            "
            v-on:click="changeStyle"
            >B</Button
          >
          <div
            style="
              margin: auto 0px auto 10px;
              margin: auto 0;
              margin-left: 10px;
            "
          >
            <verte
              picker="square"
              model="hex"
              v-bind:show-history="false"
              menu-position="right"
              v-model="styleObject['color']"
            ></verte>
          </div>
          <div
            class="dropdown"
            style="margin: auto 0px auto 10px; margin-left: 10px"
          >
            <button
              class="btn btn-secondary dropdown-toggle"
              type="button"
              id="dropdownMenu2"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img
                src="https://www.gstatic.com/images/icons/material/system_gm/1x/format_align_left_white_20dp.png"
                alt=""
              />
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
              <button
                class="dropdown-item"
                type="button"
                @click="styleObject['textAlign'] = 'left'"
              >
                <img
                  src="https://www.gstatic.com/images/icons/material/system_gm/1x/format_align_left_black_20dp.png"
                  alt=""
                />
              </button>
              <button
                class="dropdown-item"
                type="button"
                @click="styleObject['textAlign'] = 'center'"
              >
                <img
                  src="https://www.gstatic.com/images/icons/material/system_gm/1x/format_align_center_black_20dp.png"
                  alt=""
                />
              </button>

              <button
                class="dropdown-item"
                type="button"
                @click="styleObject['textAlign'] = 'right'"
              >
                <img
                  src="https://www.gstatic.com/images/icons/material/system_gm/1x/format_align_right_black_20dp.png"
                  alt=""
                />
              </button>
            </div>
          </div>
          <div class="btn-group" style="margin: 0 0 0 10px">
            <button
              class="btn btn-secondary btn-sm dropdown-toggle"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              16px
            </button>
            <div class="dropdown-menu">
              <button class="btn" @click="styleObject['fontSize'] = '18px'">
                18px
              </button>
              <button class="btn" @click="styleObject['fontSize'] = '20px'">
                20px
              </button>
              <button class="btn" @click="styleObject['fontSize'] = '22px'">
                22px
              </button>
              <button class="btn" @click="styleObject['fontSize'] = '24px'">
                24px
              </button>
              <button class="btn" @click="styleObject['fontSize'] = '26px'">
                26px
              </button>
              <button class="btn" @click="styleObject['fontSize'] = '28px'">
                28px
              </button>
            </div>
          </div>
        </div>
        <WeSubmitButton
          style="margin-top: 10px; float: right"
          v-on:submit="submitText"
        ></WeSubmitButton>
      </div>
    </div>
    <div v-else-if="selectedTemplateIndex == 2">
      <div style="padding: 5px">
        <WeImageUploader v-model="logo" title="Resim Ekle" />
        <WeInput
          v-model="styleObject.imgUrl"
          label="Url"
          name="ImageUrl"
          ref="ImageUrlRef"
        />
      </div>
    </div>
  </div>
</template>

<script>
import anymatch from "anymatch";
import Verte from "verte";
import "verte/dist/verte.css";
export default {
  name: "EditorComponent",
  components: {
    Verte,
  },
  props: {
    selectedTemplateIndex: Number,
    textId: Number,
    imageId: Number,
  },

  data() {
    return {
      logo: {
        files: [],
        filesData: null,
        id: null,
      },
      textData: "",
      selectFiles: null,
      styleObject: {
        fontWeight: "normal",
        color: "#000000",
        textAlign: "left",
        fontSize: "16px",
        imgUrl: "",
      },
      styleValue: {
        color: "#000000",
        textAlign: "left",
        textWeight: "normal",
        fontSize: "16px",
        imgUrl: "",
      },
    };
  },
  watch: {
    logo: {
      handler(val, oldVal) {
        val.id = this.imageId;
        this.$emit("imageUploaded", val);
      },
      deep: true,
    },
  },
  methods: {
    onselect(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    previewFiles(event) {
      console.log(event.target.files);
    },
    changeStyle() {
      if (this.styleObject["fontWeight"] == "bold")
        this.styleObject["fontWeight"] = "normal";
      else this.styleObject["fontWeight"] = "bold";
    },
    submitText() {
      this.inputValue = this.textData;
      this.styleValue = this.styleObject;
      this.$emit("textTop", [this.inputValue, this.styleValue, this.textId]);
      return this.styleValue;
    },
  },
  mounted() {
    this.$emit("emit-img", value * value);
  },
};
</script>

<style>
.templateEditor {
  height: 500px;
  width: 350px;
  background-color: rgba(184, 184, 184, 0.253);
  border-radius: 10px;
}
</style>